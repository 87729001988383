<template>
  <auth-content>
    <el-header slot="header"/>

    <div>
      <form @submit.prevent="editSecurity">
        <div class="card card-content">
          <div class="card-header">
            <el-card-title :title="$t('form.security.password.title')"/>
          </div>
          <div class="card-body pt-2">
            <el-alert type="warning">{{ $t('form.security.password.subtitle') }}</el-alert>
            <div class="row">
              <div class="col-lg-5">
                <label class="d-block mb-2">
                  <div class="font-weight-bold mb-1 label-required">{{ $t('form.security.password.old') }}</div>
                  <input v-model="old_password" type="password" autocomplete="current-password" class="form-control">
                </label>
                <label class="d-block mb-2">
                  <div class="font-weight-bold mb-1 label-required">{{ $t('form.security.password.new') }}</div>
                  <input v-model="password" type="password" autocomplete="new-passord" class="form-control">
                </label>
                <label class="d-block mb-0">
                  <div class="font-weight-bold mb-1 label-required">{{ $t('form.security.password.new_confirm') }}</div>
                  <input v-model="password_confirmation" type="password" autocomplete="new-passord" class="form-control">
                </label>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button v-if="!loading" class="btn btn-success text-white btn-lg">{{ $t('common.save') }}</button>
            <div v-if="loading" class="bg-white pt-2 pb-1">
              <span class="text-primary"><loader></loader></span>
            </div>
          </div>
        </div>

      </form>
    </div>

  </auth-content>
</template>

<script>

import { USER_UPDATE } from '../../store/mutations-types'

export default {
  name: 'UserSecurityForm',
  data () {
    return {
      old_password: '',
      password: '',
      password_confirmation: '',
      loading: false
    }
  },
  methods: {
    editSecurity: function () {
      this.loading = true
      this.user_id = this.$store.getters['auth/getId'];
      this.$store.dispatch('auth/' + USER_UPDATE,  {
        old_password: this.old_password,
        password: this.password,
        password_confirmation: this.password_confirmation,
        user_id: this.user_id
      }).then(() => {
        this.loading = false
        this.notifSuccess(this.$t('form.user.password_edit_success'))
        this.old_password = ''
        this.password = ''
        this.password_confirmation = ''
      })
      .catch(error => {
        this.loading = false
        this.notifError(error)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
img.preview {
  object-fit: cover;
  width: 35px;
  height: 35px;
}
</style>

<template>
  <auth-content>
    <el-header slot="header"></el-header>

    <form @submit.prevent="editSettings" v-show="world && global">
      <div class="card card-content">
        <div class="card-header">
          <el-card-title :title="$t('form.world.title.global')"/>
        </div>
        <div class="card-body">

          <div class="row">
            <div class="col-md-6">

              <div class="file-upload-form">
                <label class="mt-2 mb-1 font-weight-bold">
                  {{ $t('form.world.logo') }}
                </label>
                <input ref="logoInput" class="d-none" type="file" @change="previewLogo" accept="image/gif, image/jpeg, image/png">
                <div v-if="world.logo">
                  <el-avatar v-if="world.logo.base64" :src="world.logo.base64"></el-avatar>
                  <el-avatar v-if="world.logo.thumb" :src="world.logo.thumb"></el-avatar>
                  <div @click="changeLogo()" class="ml-2 btn btn-sm btn-light">{{ $t('form.world.logo_change') }}</div>
                </div>
                <div>
                  <div @click="changeLogo()" class="btn btn-sm btn-light" v-if="!world.logo">{{ $t('form.world.logo_add') }}</div>
                </div>
              </div>

              <label class="mt-2 mb-1 font-weight-bold label-required">{{ $t('form.world.name') }}</label>
              <input required v-model="world.name" class="form-control" type="text" :placeholder="$t('form.world.name')"/>

              <label class="mt-2 mb-1 font-weight-bold label-required">{{ $t('form.world.currency') }}</label>
              <select
                :placeholder="$t('form.world.currency')"
                v-model="world.currency_id"
                class="form-control"
              >
                <option v-for="(item, key) in global.currencies" :key="key" :value="key" :selected="key == world.currency_id">{{ item }}</option>
              </select>

            </div>
          </div>

        </div>

        <div class="card-footer">
          <button v-if="!loading" class="btn btn-lg btn-success text-white">{{ $t('common.save') }}</button>
          <div v-if="loading" class="bg-white p-2">
            <span class="text-primary"><loader></loader></span>
          </div>
        </div>

      </div>
    </form>

  </auth-content>
</template>

<script>

import store from '../../store'
import { WORLD_REQUEST, WORLD_UPDATE, GLOBAL_DATA } from '../../store/mutations-types'


export default {
  name: 'WorldSettingsForm',
  data () {
    return {
      loading: false,
      world: false,
      global: false
    }
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('world/' + WORLD_REQUEST, to.params.world).then(world => {
      store.dispatch('global/' + GLOBAL_DATA).then(data => {
        next(vm => {
          vm.world = {...world}
          vm.global = {...data}
        })
      })
    })
  },
  methods: {
    previewLogo (event) {
      // Reference to the DOM input element
      var input = event.target
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader()
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.world.logo = {
            'base64': e.target.result,
            'file': input.files[0],
          }
        }
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0])
      }
    },
    changeLogo () {
      this.world.logo = null
      this.$refs.logoInput.click()
    },
    editSettings () {
      this.loading = true

      let worldFormData = new FormData();
      worldFormData.append('id', this.world.id)
      worldFormData.append('name', this.world.name)
      worldFormData.append('currency_id', this.world.currency_id)
      if (this.world.logo && this.world.logo.file) {
        worldFormData.append('logo', this.world.logo.file)
      }
      if (this.world.logo === null) {
        worldFormData.append('logo', null)
      }

      this.$store.dispatch('world/' + WORLD_UPDATE, worldFormData).then(data => {
        this.loading = false
        this.notifSuccess(this.$t('form.world.settings_edit_success'))
        this.world = {...data}
      })
      .catch(error => {
        this.loading = false
        this.notifError(error)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
